import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Container, Col, Row } from "react-bootstrap";
import {
  delCategoriasByCategoria,
  getCategoriasByNegocio,
  postAddCategoria,
  putCategoriasByCategoria,
  UseCategoriasRest,
  UseNegociosRest,
} from "../servicios/api";
import styled from "styled-components";
import Treeview from "./Treeview";
import { groupBy } from "../utils/funciones";
import { IconAdd } from "../iconos/IconAdd";

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 70%;
  }
`;

function OptionCategorias({ categorias }) {
  if (!categorias) return <></>;
  const categoriaRaiz = categorias.find((c) => c?.id_categoria_padre === null);
  const categoriaPrimeras = categorias.filter(
    (c) => c?.id_categoria_padre === categoriaRaiz?.id_categoria
  );
  return categoriaPrimeras?.map(
    (c, i) =>
      c?.nombre_categoria && (
        <option key={JSON.stringify(c) + i} value={c?.id_categoria}>
          {c?.nombre_categoria}
        </option>
      )
  );
}
const FormSelectCaterias = ({ categorias, inicial, setCatSelect }) => {
  const [categoriaSelec, setCategoriaSelec] = useState();
  return (
    <Form.Select
      value={categoriaSelec || inicial}
      className="mb-3 w-100"
      onChange={(e) => {
        setCategoriaSelec(e.target.value);
        setCatSelect(e.target.value);
      }}
    >
      <option value={0}>RAIZ</option>
      <OptionCategorias categorias={categorias} />
    </Form.Select>
  );
};

function ModalEditarCategorias({ producto, show, handleClose, refresh }) {
  const [unidadesNegocios, setUnidadesNegocios] = useState();
  const [negocioSelect, setNegocioSelect] = useState();
  const [categorias3, setCategorias3] = useState();
  const [categorias, setCategorias] = useState();
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState();
  const [nuevaCategoria, setNuevaCategoria] = useState();
  const [editarCategoria, setEditarCategoria] = useState("");
  const [CatSelect, setCatSelect] = useState();
  const fetchDataNegocios = async () => {
    const negocios = await UseNegociosRest();
    setUnidadesNegocios(negocios);
  };

  const fetchData = async () => {
    negocioSelect || setNegocioSelect(unidadesNegocios[0]);
    const Categorias = await UseCategoriasRest(
      negocioSelect?.nombre || "Ortopedia PROVEX"
    );
    setCategorias3(Categorias);

    const resCategorias = await getCategoriasByNegocio(negocioSelect?.id);
    setCategorias(resCategorias);
  };
  const formatCategorias = (categorias) => {
    if (!categorias?.length) return;
    const temp1 = groupBy(categorias, "id_categoria_padre");
    return {
      name: "categorias",
      children: Object.keys(temp1).map((c) => {
        const cat = categorias.find((cc) => cc?.id_categoria_padre === +c);

        return {
          ...cat,
          name: cat.nombre_categoria_padre,
          children: temp1[c].map((ccc) => ({
            ...ccc,
            name: ccc?.nombre_categoria,
          })),
        };
      }),
    };
  };

  const formatCategorias3 = (categorias) => {
    if (!categorias?.length) return;
    const categoriaRaiz = categorias.find((c) => c.id_categoria_padre === null);
    const categoriaPrimeras = categorias.filter(
      (c) => c.id_categoria_padre === categoriaRaiz.id_categoria
    );
    const categoriaSegundas = categorias.filter(
      (c) =>
        c.id_categoria_padre !== categoriaRaiz.id_categoria &&
        c.id_categoria_padre !== null
    );

    const groupCategoriaPrimeras = groupBy(
      categoriaPrimeras,
      "id_categoria_padre"
    );
    const groupcategoriaSegundas = groupBy(
      categoriaSegundas,
      "id_categoria_padre"
    );
    return {
      ...categoriaRaiz,
      name:
        categoriaRaiz.id_categoria +
        "?#%" +
        categoriaRaiz?.nombre_categoria_padre,
      children: groupCategoriaPrimeras[categoriaRaiz.id_categoria]?.map(
        (cc) => ({
          ...cc,
          name: cc.id_categoria + "?#%" + cc?.nombre_categoria,

          children: groupcategoriaSegundas[cc?.id_categoria]?.map((ccc) => ({
            ...ccc,
            name: ccc.id_categoria + "?#%" + ccc?.nombre_categoria,
          })),
        })
      ),
    };
  };

  const catSelec = categorias?.find(
    (cat) => cat.nombre_categoria === categoriaSeleccionada?.name
  );

  const catPadSelec = categorias?.find(
    (cat) => cat.nombre_categoria_padre === categoriaSeleccionada?.name
  );

  const handleGuardar = async (e) => {
    const NuevaCategoria = {
      nombre: nuevaCategoria,
      id_categoria_padre: CatSelect ?? 0,
      idnegocio: negocioSelect?.id,
    };
    if (!nuevaCategoria) {
      if (
        window.confirm(
          "¿Desea seguro que desea guardar los cambios en esta categoria?"
          // JSON.stringify({ editarCategoria, categoriaSeleccionada })
        )
      ) {
        await putCategoriasByCategoria(editarCategoria);
        fetchData();
      }
    } else if (
      window.confirm(
        `Desea agregar la categoria "${nuevaCategoria}" dentro de ${
          categorias.find((c) => c.id_categoria === +CatSelect)
            ?.nombre_categoria || "RAIZ"
        } en la unidad de negocio ${negocioSelect?.nombre}`
      )
    ) {
      const res = await postAddCategoria(NuevaCategoria);
      fetchData();
    }
  };
  const handleDelete = async (categoria) => {
    if (
      window.confirm(
        "Estas seguro que desea eliminar la categoria " +
          categoria?.nombre_categoria
      )
    ) {
      await delCategoriasByCategoria(categoria.id_categoria);
      fetchData();
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negocioSelect]);

  useEffect(() => {
    fetchDataNegocios();
  }, []);
  return (
    <StyledModal show={show} onHide={handleClose} dialogClassName="modal-2w">
      <StyledModal.Header closeButton>
        <StyledModal.Title>Categorias</StyledModal.Title>
      </StyledModal.Header>
      <Container>
        <Row>
          <Col xs={6}>
            <Form>
              <StyledModal.Body>
                <div>
                  <Form.Select
                    onChange={(e) =>
                      setNegocioSelect(
                        unidadesNegocios?.find(
                          (un) => un.id === +e.target.value
                        )
                      )
                    }
                    aria-label="Default select example"
                  >
                    {unidadesNegocios?.map((un) => (
                      <option value={un.id}>{un.nombre}</option>
                    ))}
                  </Form.Select>
                </div>

                {categorias?.length && (
                  <Treeview
                    handleDelete={handleDelete}
                    Datos={formatCategorias3(categorias)}
                    categoriaSeleccionada={categoriaSeleccionada}
                    setSelected={(catSel) => {
                      setCategoriaSeleccionada(catSel);
                      setNuevaCategoria(undefined);
                      //   id_categoria: catSel.id_categoria,
                      //   nombre_categoria: catSel.name,
                      //   id_categoria_padre:
                      //     categorias.find(
                      //       (c) => c.id_categoria === catSel.id_categoria
                      //     )?.id_categoria_padre ?? "",
                      // });
                      setEditarCategoria({
                        id_categoria: catSel.id_categoria,
                        nombre_categoria: catSel.name,
                        id_categoria_padre:
                          categorias.find(
                            (c) => c.id_categoria === catSel.id_categoria
                          )?.id_categoria_padre ?? "",
                      });
                    }}
                  />
                )}
              </StyledModal.Body>
            </Form>
          </Col>
          <Col xs={6} className="d-flex justify-content-center py-3">
            <Container className="">
              <Row
                style={{
                  justifyContent: "right",
                }}
              >
                <Button
                  size="sm"
                  style={{
                    width: "auto",
                  }}
                  onClick={(_) => {
                    setNuevaCategoria("Nueva categoria");
                    setCategoriaSeleccionada({
                      name: "Nueva categoria",
                      id: 1000,
                      parent: 0,
                      children: [],
                    });
                  }}
                >
                  <IconAdd /> NUEVA CATEGORIA
                </Button>
              </Row>
              <Row>
                <Col sm="8">
                  <h3>
                    {nuevaCategoria !== undefined
                      ? "Nueva categoria"
                      : catSelec
                      ? "Editar categoria"
                      : "Editar categoria padre"}
                  </h3>
                </Col>

                {/* <Col style={{ textAlign: "right" }}>
                  <Button variant="danger" onClick={handleDelete}>
                    Eliminar
                  </Button> 
                </Col>*/}
              </Row>
              <Row>
                {nuevaCategoria !== undefined ? (
                  <div className="px-4 pt-3 w-100">
                    <>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label disabled>Categoria padre</Form.Label>
                        <FormSelectCaterias
                          setCatSelect={setCatSelect}
                          inicial={1}
                          categorias={categorias}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 w-100">
                        <Form.Label>Categoria</Form.Label>
                        <Form.Control
                          placeholder="Categoria"
                          onChange={(e) => setNuevaCategoria(e.target.value)}
                          value={nuevaCategoria}
                        />
                      </Form.Group>
                    </>
                  </div>
                ) : (
                  <div className="px-4 pt-3 w-100">
                    {/* {catSelec ? ( */}
                    <>
                      <Form.Label disabled>Categoria padre</Form.Label>
                      <FormSelectCaterias
                        setCatSelect={(id_categoria_padre) => {
                          setEditarCategoria({
                            ...editarCategoria,
                            id_categoria_padre,
                          });
                        }}
                        inicial={editarCategoria?.id_categoria_padre ?? 0}
                        categorias={categorias}
                      />

                      <Form.Group className="mb-3">
                        <Form.Label>Categoria</Form.Label>
                        <Form.Control
                          placeholder="Categoria"
                          value={editarCategoria?.nombre_categoria ?? ""}
                          onChange={(e) =>
                            setEditarCategoria({
                              ...editarCategoria,
                              nombre_categoria: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </>
                    {/* ) : (
                      <>
                        <Form.Group className="mb-3 w-100">
                          <Form.Label disabled>Categoria padre</Form.Label>
                          <FormSelectCaterias
                            setCatSelect={setCatSelect}
                            inicial={0}
                            categorias={categorias}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100">
                          <Form.Label>Categoria</Form.Label>
                          <Form.Control
                            placeholder="Categoria"
                            value={
                              nuevaCategoria ||
                              catPadSelec?.nombre_categoria_padre
                            }
                            onChange={(e) => setNuevaCategoria(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )} */}
                  </div>
                )}
              </Row>
              <Row>
                <Col style={{ textAlign: "right" }}>
                  <Button variant="success" onClick={handleGuardar}>
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <StyledModal.Footer>
        <Container>
          <Row>
            <Col md={8}></Col>

            <Col style={{ textAlign: "right" }}></Col>
          </Row>
        </Container>
      </StyledModal.Footer>
    </StyledModal>
  );
}

export default ModalEditarCategorias;
