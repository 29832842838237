import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import {
  UseAddProductosRest,
  UseCategoriasRest,
  UseNegociosRest,
} from "../servicios/api";
import { CarruselImagenes } from "./carruselImagenes";

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 70%;
  }
`;
function ModalAgregarProductos2() {
  const [show, setShow] = useState(false);

  const [unidadesNegocios, setUnidadesNegocios] = useState();
  const [categorias, setCategorias] = useState();
  const productoVacio = {
    idnegocio: "1",
    codigo: "",
    nombre: "",
    carrusel: false,
    imagen: [],
    imagenes: [],
    idcategoria: "1",
    descripcion: "",
  };
  const [producto, setProducto] = useState(productoVacio);

  const handleClose = () => {
    setShow(false);
    setProducto(productoVacio);
  };

  const handleShow = () => {
    setShow(true);
    setProducto(productoVacio);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error } = await UseAddProductosRest(producto);
    if (error) alert(error);
    else {
      handleClose();
      fetchData();
    }
  };

  const fetchDataNegocios = async () => {
    const negocios = await UseNegociosRest();
    setUnidadesNegocios(negocios);
  };

  const fetchData = async () => {
    const Categorias = await UseCategoriasRest(
      producto?.negocio ?? "Ortopedia PROVEX"
    );
    setCategorias(Categorias);
  };

  const MAXSIZEFILE = 1048576;
  const [validFile, setValidFile] = useState(true);
  const [deleteImagenes, setDeleteImagenes] = useState([]);

  useEffect(() => {
    fetchDataNegocios();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producto]);

  useEffect(() => {
    ((imagen) => {
      setProducto({ ...producto, imagen });
    })(
      producto?.imagen?.filter(
        (im) => !deleteImagenes?.map((a) => a?.nombre).includes(im.nombre)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteImagenes]);
  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Agregar
      </Button>

      <StyledModal show={show} onHide={handleClose} dialogClassName="modal-2w">
        <StyledModal.Header closeButton>
          <StyledModal.Title>Agregar Productos</StyledModal.Title>
        </StyledModal.Header>
        <Container>
          <Row>
            <Col xs={6}>
              <Form>
                <StyledModal.Body>
                  <Container>
                    <Form.Select
                      value={producto?.idnegocio}
                      onChange={(e) => {
                        setProducto((prev) => ({
                          ...prev,
                          idnegocio: e.target.value,
                          negocio: unidadesNegocios.find(
                            (a) => a.id === +e.target.value
                          )?.nombre,
                        }));
                      }}
                    >
                      {unidadesNegocios &&
                        unidadesNegocios.map((negocio) => (
                          <option
                            value={negocio.id}
                            key={JSON.stringify(negocio)}
                          >
                            {negocio.nombre}
                          </option>
                        ))}
                    </Form.Select>
                    <br />
                    <Form.Floating className="mb-3">
                      <Form.Control
                        maxLength="10"
                        id="Codigo"
                        type="text"
                        value={producto?.codigo}
                        onChange={(e) =>
                          setProducto({
                            ...producto,
                            codigo: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="Codigo">Codigo</label>
                    </Form.Floating>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        maxLength="100"
                        id="Nombre"
                        type="text"
                        value={producto?.nombre}
                        onChange={(e) =>
                          setProducto({
                            ...producto,
                            nombre: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="Nombre">Nombre</label>
                    </Form.Floating>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        maxLength="1500"
                        id="Descripcion"
                        as="textarea"
                        style={{ height: "100%" }}
                        type="text"
                        value={producto?.descripcion}
                        onChange={(e) =>
                          setProducto({
                            ...producto,
                            descripcion: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="Descripcion">Descripcion</label>
                    </Form.Floating>
                    <Form.Select
                      value={producto?.idcategoria}
                      onChange={(e) => {
                        setProducto({
                          ...producto,
                          idcategoria: e.target.value,
                        });
                      }}
                    >
                      {!!categorias?.length &&
                        categorias?.map((categoria) => (
                          <option
                            value={categoria.id_categoria}
                            key={JSON.stringify(categoria)}
                          >
                            {categoria.nombre_categoria_padre}
                            {" -> "}
                            {categoria.nombre_categoria}
                          </option>
                        ))}
                    </Form.Select>
                    <br />
                  </Container>
                </StyledModal.Body>
              </Form>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <Container>
                <Row xs={10}>
                  {!!producto.imagenes.length && (
                    <CarruselImagenes
                      width={200}
                      height={200}
                      Imagenes={producto.imagen}
                      btnDel={true}
                      onDelete={setDeleteImagenes}
                      nuevasImagenes={producto.imagenes}
                    />
                  )}
                </Row>
                <br />
                <Row>
                  <Form.Group controlId="Imagenes" className="mb-3">
                    <Form.Label>Cargar imagenes</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".png,.jpg,.jpeg,.webp"
                      multiple
                      invalid={!validFile + ""}
                      onChange={(e) => {
                        const imagenes = Array.from(e.target.files);
                        const auxValidFiles = imagenes.some(
                          (a) => a.size >= MAXSIZEFILE
                        );
                        if (auxValidFiles) setValidFile(auxValidFiles);
                        else
                          setProducto({
                            ...producto,
                            imagenes,
                          });
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Check
                    type={"checkbox"}
                    label={"Producto en carrusel"}
                    value={!!+producto?.carrusel}
                    checked={!!+producto?.carrusel}
                    onChange={(e) => {
                      setProducto({
                        ...producto,
                        carrusel: !producto?.carrusel,
                      });
                    }}
                  />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <StyledModal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </StyledModal.Footer>
      </StyledModal>
    </>
  );
}

export default ModalAgregarProductos2;
