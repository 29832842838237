import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TablaProductos } from "./componentes/TablaProductos";
import { LoginPage } from "./componentes/login";
import { isTokenExpired } from "./utils/funciones";

function App() {
  const token = localStorage.getItem("token");
  if (isTokenExpired(token)) {
    localStorage.removeItem("token");
    return <LoginPage />;
  } else return <>{!!token ? <TablaProductos /> : <LoginPage />}</>;
}

export default App;
